<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>자원관리</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="출고현황 상세"></CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <table class="table-row mb-10">
                                <colgroup>
                                    <col width="150">
                                    <col width="*">
                                    <col width="150">
                                    <col width="*">
                                    <col width="150">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>교재정보</th>
                                        <td>
                                            Biz Basics 1 - 2014 (ver.1)
                                        </td>
                                        <th>수량</th>
                                        <td>
                                            2
                                        </td>
                                        <th>금액</th>
                                        <td>
                                            200,000 원
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table-row mb-10">
                                <colgroup>
                                    <col width="150">
                                    <col width="*">
                                    <col width="150">
                                    <col width="*">
                                    <col width="150">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>교재정보</th>
                                        <td>
                                            Biz Basics 1 - 2014 (ver.2)
                                        </td>
                                        <th>수량</th>
                                        <td>
                                            3
                                        </td>
                                        <th>금액</th>
                                        <td>
                                            300,000 원
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table-row mt-40">
                                <colgroup>
                                    <col width="150">
                                    <col width="*">
                                    <col width="150">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>요청자</th>
                                        <td>
                                            Amy K(김은지)
                                        </td>
                                        <th>등록일</th>
                                        <td>
                                            2019-00-00
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>출고현황</th>
                                        <td colspan="3">
                                            <div class="float-left">요청</div>
                                            <div class="float-left pl-20">
                                                <p>완료 0000-00-00</p>
                                                <p>취소 0000-00-00</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>내부/외부</th>
                                        <td colspan="3">
                                            내부
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>용도</th>
                                        <td colspan="3">
                                            학생용
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>고객사명</th>
                                        <td colspan="3">
                                            캐럿솔루션즈
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>과정명</th>
                                        <td colspan="3">
                                            개발 퍼블리싱 과정
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>유/무상</th>
                                        <td colspan="3">
                                            무상
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>무상 사유</th>
                                        <td colspan="3">
                                            사유입니다.
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>출고희망일</th>
                                        <td colspan="3">
                                            2019-08-27
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>비고<br>(세부내용)</th>
                                        <td colspan="3">
                                            세부내용은…
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <button class="btn-default float-left mt-30">목록</button>
                            <button class="btn-default float-left mt-30 ml-10">삭제</button>
                            <button class="btn-default float-right mt-30">수정</button>
                            <button class="btn-default float-right mt-30 mr-10">출고 완료</button>
                            <button class="btn-default float-right mt-30 mr-10">출고 취소</button>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 


export default {
}
</script>

<style lang="scss" scoped>
</style>